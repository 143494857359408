import VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinComponent from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/skinComps/VerticalAnchorsMenuSymbolSkin/VerticalAnchorsMenuSymbolSkin.skin';
import VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinController from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/VerticalAnchorsMenu.controller';


const VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkin = {
  component: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinComponent,
  controller: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkinController
};


export const components = {
  ['VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkin']: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolSkin
};


// temporary export
export const version = "1.0.0"
