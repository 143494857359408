import * as React from 'react';
import classnames from 'classnames';
import {
  IVerticalAnchorsMenuItem,
  IVerticalAnchorsMenuProps,
  IVerticalAnchorsMenuStyleProps,
  PaddingDirection,
} from '../../VerticalAnchorsMenu.types';
import Link from '../../../Link/viewer/Link';
import * as translations from '../constants';
import { ARIA_LABELLED_BY_PREFIX } from '../constants';

const getPaddingDirection = (
  itemsAlignment: IVerticalAnchorsMenuProps['itemsAlignment'],
  orientation: string,
): PaddingDirection => {
  if (itemsAlignment === 'right' || itemsAlignment === 'left') {
    return itemsAlignment;
  } else {
    if (orientation === 'right') {
      return 'right';
    } else {
      return 'left';
    }
  }
};

const VerticalAnchorsMenuCommonSkin: React.FC<
  IVerticalAnchorsMenuProps & IVerticalAnchorsMenuStyleProps
> = props => {
  const {
    skin,
    style,
    anchors,
    id,
    translate,
    itemsAlignment,
    orientation,
    linkContent: LinkContent,
    activeAnchor,
    currentPageHref = './',
    onMouseEnter,
    onMouseLeave,
  } = props;

  const _renderAnchor = (anchorItem: IVerticalAnchorsMenuItem, idx: number) => {
    const { name, compId: anchorCompId, dataId: anchorDataId } = anchorItem;
    const ariaLabelledBy = `${ARIA_LABELLED_BY_PREFIX}${anchorDataId}`;
    const isActiveAnchor = activeAnchor?.compId === anchorCompId;
    const ariaCurrentProp = isActiveAnchor ? { 'aria-current': true } : {};
    return (
      <li key={idx} className={style.listItem} {...ariaCurrentProp}>
        <Link
          className={classnames(style.link, {
            [style.activeAnchor]: isActiveAnchor,
          })}
          href={currentPageHref}
          target="_self"
          anchorCompId={anchorCompId}
          anchorDataId={anchorDataId}
          aria-labelledby={ariaLabelledBy}
          activateByKey={null}
        >
          <LinkContent
            label={name}
            paddingDirection={getPaddingDirection(itemsAlignment, orientation)}
            activeAnchor={isActiveAnchor}
            id={ariaLabelledBy}
          />
        </Link>
      </li>
    );
  };

  const ariaLabel = translate!(
    translations.ARIA_LABEL_NAMESPACE,
    translations.ARIA_LABEL_KEY,
    translations.ARIA_LABEL_DEFAULT,
  );

  return (
    <nav
      id={id}
      aria-label={ariaLabel}
      className={style[skin]}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ul className={style.menuContainer}>
        {anchors &&
          anchors.map((anchorItem, idx) => {
            return _renderAnchor(anchorItem, idx);
          })}
      </ul>
    </nav>
  );
};

export default VerticalAnchorsMenuCommonSkin;
